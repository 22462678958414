<template lang="html">
  <div class="component card">
    <div class="title">
      <h1>Billing</h1>
    </div>
    <div class="payments inside-card">
      <h3>Billing Info</h3>
      <p>We use <a href="https://www.stripe.com" target="_blank">Stripe</a> as our payment processor. Your card info is stored securely by them, and never seen by us or our servers.</p>
      <div v-if="paymentMethod">
        <p><b>Default card:</b> Your {{paymentMethod.safe_payment_info.brand | capitalize}} ending in {{paymentMethod.safe_payment_info.last4}}.</p>
        <div v-if="loading" class="loader"></div>
        <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
        <router-link to="/dashboard/add_card" tag="button" class="small-button">Change your card</router-link>
        <button v-if="!loading" @click="showDeleteCardModal" class="small-button red">Delete your card</button>
        <confirm-modal 
            v-if="displayDeleteCardModal" 
            @destructive="deleteCard" 
            @gentle="hideDeleteCardModal" 
            v-bind:title="'Are you sure?'" 
            v-bind:body="'Deleting your card means you won\'t be able to pay for alerts. Are you sure you want to do this?'"
            v-bind:destructiveButton="'Delete your card'"
            v-bind:gentleButton="'Keep your card'"
            v-bind:shouldSpin="true">
        </confirm-modal>
      </div>
      <div v-else>
        <p>You have no default card on your account.</p>
        <router-link to="/dashboard/add_card" tag="button" class="small-button">Add a new card</router-link>
      </div>
    </div>
    <div v-if="invoices.length > 0" class="payments inside-card">
      <h3>Your Invoices</h3>
      <invoice 
        v-for="invoice in invoices"
        v-bind:key="invoice.alert"
        v-bind:invoiceObject="invoice">
      </invoice>
    </div>
  </div>
</template>

<script>
import ConfirmModal from '@/components/ConfirmModal.vue';
import Invoice from '@/components/Invoice.vue';

export default {
  components: {Invoice, ConfirmModal},
  data() {
    return {
      loading: false,
      errorMessage: null,
      displayDeleteCardModal: false,
    }
  },
  methods: {
    showDeleteCardModal: function() {
      this.displayDeleteCardModal = true;
    },
    hideDeleteCardModal: function() {
      this.displayDeleteCardModal = false;
    },
    deleteCard: function() {
      this.loading = true;

      this.$store.dispatch('deletePaymentMethod', this.paymentMethod.id).then((response) => {
        console.log("Deleted payment method");
        this.loading = false;
      }).catch((error) => {
        console.log("Couldn't delete the PM:", error);
        this.errorMessage = error.response.data.error;
        this.loading = false;
      });
    },
  },
  computed: {
    payments: function () {
      return this.$store.state.stripeAccount.payments_by;
    },
    invoices: function () {
      return this.$store.state.stripeAccount.invoices;
    },
    paymentMethod: function() {
      return this.$store.state.stripeAccount.payment_method;
    },
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
}
</script>

<style lang="scss" scoped>

.card {
  padding: 2em 0;
}

.payments {

  h3 {
    padding: 0;
    margin: 0.2em;
  }

}

.small-button {
  .destructive {
    color: $red;
    font-size: 14px;
    background: red;
  }
}
</style>
