<template lang="html">
  <div class="modal-container">
    <div class="confirm-modal modal">
      <h1>{{title}}</h1>
      <h3 v-if="subtitle">{{subtitle}}</h3>
      <p>{{body}}</p>
      <div v-if="showSpinner" class="loader"></div>
      <div v-else class="buttons">
        <button class="small-button blue" @click="gentleButtonPressed">{{gentleButton}}</button>
        <button class="small-button red" @click="destructiveButtonPressed">{{destructiveButton}}</button>
      </div>
    </div>
    <div class="modal-backdrop" @click="gentleButtonPressed"></div>
  </div>
</template>

<script>

export default {
  name: 'ConfirmModal',
  props: ["title", "subtitle", "body", "gentleButton", "destructiveButton", "shouldSpin",],
  data: function () {
    return {
      showSpinner: false,
    }
  },
  methods: {
    gentleButtonPressed () {
      this.showSpinner = true;
      
      this.$emit('gentle');
    },
    destructiveButtonPressed () {
      this.showSpinner = true;
      
      this.$emit('destructive');
      console.log(this.showSpinner);
    },
  },
}
</script>

<style lang="scss" scoped>

.modal-backdrop {

}

.confirm-modal {
  background-color: $emphasis;
  p {
    margin: 1em auto;
    max-width: 464px;
    min-width: 17em;

  }
}


</style>
