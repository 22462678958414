<template>
  <div class="dashboard">
    <div id="dash-hero" class="component">
      <div class="title">
        <h1>Your Dashboard</h1>
      </div>
    </div>
    <div v-if="userIsLoaded">
      <alerts></alerts>
      <subscription v-if="subscription"></subscription>
      <billing ref="billing"></billing>
    </div>
    <div v-else-if="!userIsLoaded && isLoggedIn" class="card">
      <div class="loader">
      </div>
    </div>
    <login-modal v-if="!isLoggedIn" @didAuthenticate="authenticatedFromModal"></login-modal>
    <button class='big-button' @click="logout">Logout of Bottlebeagle</button>
  </div>
</template>

<script>
import LoginModal from '@/components/login-modal.vue';
import Alerts from '@/components/Alerts.vue';
import Billing from '@/components/Billing.vue';
import Subscription from '@/components/Subscription.vue';

export default {
  name: 'Dashboard',
  components: {
    Alerts, Billing, Subscription, LoginModal,
  },
  mounted() {
    if (this.isLoggedIn && !this.didScroll) {
      let whereTo = this.$route.params.scrollTo;

      if (whereTo) {
        this.$nextTick(() => {
          this.$refs[whereTo].$el.scrollIntoView({ block: 'start', scrollBehavior: 'smooth' });
          this.didScroll = true;
        });      
      }
      
    }

    // check if there's a specific product, and hide hero if so
    if ((this.$route.query.itemId && this.$route.query.retailer) || this.$route.path != "/") {
      const heroElement = document.getElementById('hero');
      if (heroElement) {
        heroElement.classList.add('hide-hero');
      }
    }
  },
  data() {
    return {
      didScroll: false,
    }
  },
  watch: {
    isLoggedIn (newValue) {
      console.log("Is logged in changed to", newValue);
      if (newValue === true && this.didScroll === false) {
        // we can safely navigate them
        let whereTo = this.$route.params.scrollTo;

        this.$nextTick(() => {
          this.$refs[whereTo].$el.scrollIntoView({ block: 'start', scrollBehavior: 'smooth' });
          this.didScroll = true;
        });
      }
    }
  },
  methods: {
    authenticatedFromModal: function (newUser) {
      console.log("Logged in");
      setTimeout(() => {
        this.showLoginModal = false;
      }, 1000);
    },
    logout: function () {
      this.$store.dispatch('fullLogout');
      this.$router.push('/');
    }
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.state.token != "";
    },
    userIsLoaded: function () {
      return this.$store.state.phoneNumber != "";
    },
    subscription: function() {
      return this.$store.state.stripeAccount.subscription;
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard {
  margin-top: 6em;
  h1 {
    color: #FFFFFF;
    font-size: 36px;
    font-family: $header-font;
  }
  #dash-hero {
    margin-top: 125px;
    .title {
      background: none;
    }
  }
}
</style>
