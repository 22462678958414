<template lang="html">
  <div class="component card">
    <div v-if="subscription.cancel_at_period_end">
      <div class="title">
        <h1>Your Subscription</h1>
        <p><em>Your subscription will be canceled on {{shortDate(subscription.cancel_at)}}</em>.</p>
        <p>After that, your active alerts will stop (unless you've paid for them separately).</p>
        <div class="buttons">
          <button class="big-button" @click="resumeSubscription">Resume your subscription</button>
        </div>
        </div>
    </div>
    <div v-else>
      <div class="title">
        <h1>Your Subscription</h1>
        <p>Your {{subscription.plan_info.name}} subscription is {{subscription.status}}. You have {{subscription.remaining_alerts}} active alerts left to use.</p>
        <p>Your next billing date is {{shortDate(subscription.current_period_end)}}.</p>
        <p v-if="errorMessage">{{errorMessage}}</p>
        <div class="buttons">
          <router-link to="/dashboard/change_subscription" tag="button" class="small-button">Upgrade or downgrade</router-link>
          <button class="small-button red" @click="showCancelModal">Cancel your subscription</button>
          <confirm-modal 
            v-if="displayCancelModal" 
            @destructive="confirmCancelSubscription" 
            @gentle="hideCancelModal" 
            v-bind:title="'Are you sure?'" 
            v-bind:body="cancelModalMessage" 
            v-bind:destructiveButton="'Cancel your alerts'"
            v-bind:gentleButton="'Keep your alerts'"
            v-bind:shouldSpin="true">
          </confirm-modal>
        </div>
        </div>
    </div>
  </div>
</template>

<script>
import ConfirmModal from '@/components/ConfirmModal.vue';
import axios from 'axios';


export default {
  name: 'Subscription',
  components: {ConfirmModal},
  data() {
    return {
      displayCancelModal: false,
      errorMessage: null,
    }
  },
  methods: {
    showCancelModal() {
      this.displayCancelModal = true;
    },
    hideCancelModal() {
      this.displayCancelModal = false;
    },
    confirmCancelSubscription() {
      console.log("cancelled");

      axios.post('cancel_subscription/', {
        subscription_id: this.subscription.id,
        cancel: true,
      }).then((response) => {
        this.displayCancelModal = false;
        // update subscription
        this.$store.dispatch('setSubscription', response.data.subscription);
      }).catch((error) => {
        console.log(error);
        this.errorMessage = error;
      });   
    },
    resumeSubscription() {
      axios.post('cancel_subscription/', {
        subscription_id: this.subscription.id,
        cancel: false,
      }).then((response) => {
        this.displayCancelModal = false;
        // update subscription
        this.$store.dispatch('setSubscription', response.data.subscription);
      }).catch((error) => {
        console.log(error);
        this.errorMessage = error;
      });  
    }
  },
  computed: {
    subscription: function() {
      return this.$store.state.stripeAccount.subscription;
    },
    cancelModalMessage: function() {
      let periodEndString = this.shortDate(this.subscription.current_period_end);
      return 'If you cancel your subscription, your alerts will continue until ' + periodEndString + '. Any alerts you paid for individually, outside of a subscription, will continue until their expiry date.';
    }
  }
}
</script>

<style lang="scss" scoped>

.title {
  .buttons {
    margin-top: 2em;
  }
}
button {
  &.small-button {
    margin: 0.2em 1em;
  }
}
</style>
