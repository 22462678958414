<template lang="html">
  <div class="baby-inside-card payment">
    <h1>{{ shortDate(invoiceObject.created) }}</h1>
    <p>{{invoiceObject.description}}</p>
    <div class="delinquent" v-if="invoiceObject.is_delinquent">
      <p >{{ displayStatus }}.</p>
      <router-link :to="{name: 'retryinvoice', params: {invoiceId: invoiceObject.id} }">Pay now</router-link>
    </div>
    <div v-else>
      <p>{{ displayPrice }} – {{ displayStatus }}</p>
      <p><a target="_blank" :href="invoiceObject.invoice_pdf" v-if="invoiceObject.invoice_pdf">Download</a></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Invoice',
  props: ["invoiceObject"],
  computed: {
    displayPrice: function () {
      let priceInDollars = this.invoiceObject.total / 100;
      return "$"+ priceInDollars.toFixed(2);
    },
    displayStatus: function () {
      if (this.invoiceObject.is_delinquent) {
        return "Unpaid. Your payment is overdue";
      } else {
        return this.capitalizeFirstLetter(this.invoiceObject.status);
      }
    },
  },
  methods: {
    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  }
}
</script>

<style lang="scss" scoped>
.payment {
  
  // margin: 10px 0;
  // padding: 3px 0;
  // h1 {
  //   margin: 0 0;
  // }
  // p {
  //   margin: 0px 0;
  // }

  .delinquent {
    p {
      color: red;
    }
    a {
      color: rgb(173, 93, 93);
      font-size: 20px;
      font-weight: 600;
    }
  }
}
</style>
